import 'common/styles.css'

// eslint-disable-next-line
import ViewportProvider from 'components/shared/layout/LayoutContext'
import SEO from 'components/SEO'
import React from 'react'
import { Helmet } from 'react-helmet'

const SEO_KEYWORDS = [
    `projector`,
    'design',
    'graphic design',
    'design software',
    'templates',
    'collaboration',
    'story',
    `storytelling`,
    `publishing`,
    `design`,
    `visual communication`,
    'presentations',
    'social media',
    'print',
    'instagram',
    'deck',
    'gif',
    'gifs'
]

const VIEWPORT_DEF = 'width=device-width, initial-scale=1'

interface IProps {
    title: string
    children?: any
    imageUrl?: string
    description?: string
    background?: string
    primaryForeground?: string
    noFooter?: boolean
    noTitlebar?: boolean
}

const BasePage = React.memo(
    ({
        title,
        children,
        imageUrl = undefined,
        description = undefined,
        background = '#000000'
    }: IProps) => {
        return (
            <React.Fragment>
                <SEO title={title} keywords={SEO_KEYWORDS} imageUrl={imageUrl} description={description} />
                <Helmet>
                    <style>{`body { background: ${background} }`}</style>
                    <meta name="viewport" content={VIEWPORT_DEF} />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                </Helmet>
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        minHeight: '100vh',
                        overflowX: 'hidden',
                        background,
                        boxSizing: 'border-box'
                    }}
                >
                    <ViewportProvider>
                        {children}
                    </ViewportProvider>
                </div>
            </React.Fragment>
        )
    }
)

export default BasePage
