import { Type } from 'common/styles'
import Colors from 'common/styles/Colors'
import BasePage from 'components/shared/layout/BasePage'
import { useIsMobile } from 'components/shared/layout/LayoutContext'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div<{isMobile: boolean}>`
    padding: 64px;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    font-family: ${Type.families.brandPrimary};

    color: ${Colors.brandColors.white};
    box-sizing: border-box;
    letter-spacing: -0.8;

    ${props => props.isMobile && `padding: 40px 36px;`}
`

const Header = styled.div<{isMobile: boolean}>`
    position: relative;
    font-size: 64px;
    max-width: 700px;
    line-height: 1em;
    font-weight: 300;
    letter-spacing: -.08em;
    margin-bottom: 1em;

    ${props => props.isMobile && `font-size: 52px;`}
`
const Paragraph = styled.div<{isMobile: boolean}>`
    line-height: 1.4em;
    font-size: 21px;
    margin-bottom: 1em;

    ${props => props.isMobile && `font-size: 18px;`}

    a {
        color: ${Colors.brandColors.white};
    }
`

const Root = () => {
    const isMobile = useIsMobile()
    return (
        <BasePage noTitlebar noFooter title="Projector" background={Colors.brandColors.black}>
            <Container isMobile={isMobile}>
                <div style={{ flex: 1 }} />
                <div style={{ maxWidth: 800 }}>
                    <Header isMobile={isMobile}>Projector has been acquired by Medium.</Header>
                    <Paragraph isMobile={isMobile}>We can't thank you enough for turning to Projector for your creative projects over the last few years. Your support has meant the world to us, and we hope you continue to find beautiful ways to express your ideas. In the meantime, you can visit us over at <a href="https://medium.com">medium.com</a>.</Paragraph>
                    <Paragraph isMobile={isMobile}>- The team at Projector</Paragraph>
                </div>
                <div style={{ flex: 1 }} />
            </Container>
        </BasePage>
    )
}

export default Root
