import 'assets/fonts/Roboto_Mono/stylesheet.css'
import 'assets/fonts/Roboto/stylesheet.css'
import 'assets/fonts/Recife/stylesheet.css'
import 'assets/fonts/Scto/stylesheet.css'
import 'assets/fonts/Open_Sauce_One/stylesheet.css'
import 'assets/fonts/Open_Sauce_Sans/stylesheet.css'

const families = {
    robotoMono: '"Roboto Mono", "Courier New", monospace',
    roboto: 'Roboto, Arial, sans-serif',
    brandPrimary: 'Scto, Arial, sans-serif',
    brandSecondary: 'Recife, Georgia, serif',
    openSauceSans: '"Open Sauce Sans", sans-serif',
    openSauceOne: '"Open Sauce One", sans-serif'
}

export default { families }
