/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'

interface IProps {
    lang?: string
    meta?: any[]
    keywords?: string[]
    title: string
    imageUrl?: string
    description?: string
}

function SEO({ lang = 'en', meta = [], keywords = [], title, imageUrl, description = '' }: IProps) {
    const metaDescription = description
        ? description
        : `Whether you're building your brand solo or with a team, 
        use Projector to craft well designed stories that will elevate your presence and captivate any audience.`

    const socialImageUrl = imageUrl ? imageUrl : 'https://projector.com/social/open-graph-default.png?123456'

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={`%s`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:image`,
                    content: socialImageUrl
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`
                },
                {
                    name: `twitter:creator`,
                    content: '@Projector'
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                },
                {
                    property: `twitter:image`,
                    content: socialImageUrl
                }
            ]
                .concat(
                    keywords.length > 0
                        ? {
                              name: `keywords`,
                              content: keywords.join(`, `)
                          }
                        : []
                )
                .concat(meta)}
        />
    )
}

export default SEO
