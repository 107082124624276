import React from 'react'

export enum ResponsiveFormat {
    MOBILE = 0,
    TABLET,
    LAPTOP,
    WIDE_DISPLAY
}

export const breakpoints = {
    mobile: 760,
    tablet: 1024,
    laptop: 1440
}

interface ContextProps {
    responsiveFormat: ResponsiveFormat
    isClientSide: boolean
}

const getResponsiveFormat = (width: number) => {
    if (width < breakpoints.mobile) {
        return ResponsiveFormat.MOBILE
    } else if (width < breakpoints.tablet) {
        return ResponsiveFormat.TABLET
    } else if (width < breakpoints.laptop) {
        return ResponsiveFormat.LAPTOP
    } else {
        return ResponsiveFormat.WIDE_DISPLAY
    }
}

const isSSR = typeof window === 'undefined'

// We default to a mobile size to prioritize the mobile experience for server-side rendering
const defaultSize = { width: breakpoints.mobile - 1, height: 800 }

const viewportContext = React.createContext<ContextProps>({
    responsiveFormat: getResponsiveFormat(isSSR ? defaultSize.width : window.innerWidth),
    isClientSide: false
})

const ViewportProvider = ({ children }) => {
    const [format, setFormat] = React.useState(getResponsiveFormat(isSSR ? defaultSize.width : window.innerWidth))
    const [isClient, setClient] = React.useState(false)

    const handleWindowResize = () => {
        setFormat(getResponsiveFormat(window.innerWidth))
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize)
        setClient(true)
        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    return (
        <viewportContext.Provider value={{ responsiveFormat: format, isClientSide: isClient }}>
            {isClient && children}
        </viewportContext.Provider>
    )
}

export const useResponsiveFormat = () => {
    const { responsiveFormat } = React.useContext(viewportContext)
    return responsiveFormat
}

export const useIsNarrow = () => {
    const { responsiveFormat } = React.useContext(viewportContext)
    return responsiveFormat <= ResponsiveFormat.TABLET
}

export const useIsMobile = () => {
    const { responsiveFormat } = React.useContext(viewportContext)
    return responsiveFormat < ResponsiveFormat.TABLET
}

export const useIsClient = () => {
    return React.useContext(viewportContext).isClientSide
}

export default ViewportProvider
