import Color from 'color'

const lighten = (color: string, value: number) => Color(color).lighten(value).string()
const darken = (color: string, value: number) => Color(color).darken(value).string()
const alpha = (color: string, value: number) => Color(color).alpha(value).string()

const colors = {
    white: '#ffffff',
    offWhite: '#FBFBF9',
    offWhiteDark: '#F5F5F4',
    orange: '#FF806F',
    black: '#000000',
    gray: '#40403D',
    mint: '#71E8D2',
    pink: '#F7A5B1',
    lightPink: '#FFE2E6',
    hotRed: '#FF7C8E',
    blue: '#87A1FF',
    sky: '#7BB9F6',
    red: '#FF0000',
    transparent: alpha('#000', 0),
    bespokeBlue: '#8FC2F3',
    bespokeYellow: '#F9D75F',
    bespokePink: '#FC7A8D',
    lavender: '#8C70FF',
    plum: '#544579',
    silver: '#F3F5F8'
}

const brandColors = {
    black: '#000000',
    white: '#ffffff',
    gray: '#767676',
    offWhite: '#f5f5f5',
    red: '#ff5c40',
    peach: '#ffddc7',
    blue: '#0345cf',
    pink: '#f0bfff',
    green: '#0f413b',
    lightBlue: '#a7e5ff',
    // Note: These next two aren't really brand colors, but they appear in site designs
    yellow: '#F0C84B',
    teal: '#1ED3D6',
    granite: '#d8dce5',
    shadow: '#1F2229',
    silver: '#f0f1f5'
}

const opacities = {
    hard: 1.0,
    lessHard: 0.85,
    medium: 0.5,
    mediumSoft: 0.35,
    quarter: 0.25,
    soft: 0.2,
    softer: 0.08,
    placeholder: 0.55,
    previewReelMedium: 0.55
}

const grays = {
    opaque: colors.gray,
    hard: alpha(colors.gray, opacities.hard),
    lessHard: alpha(colors.gray, opacities.lessHard),
    medium: alpha(colors.gray, opacities.medium),
    soft: alpha(colors.gray, opacities.soft),
    softer: alpha(colors.gray, opacities.softer),
    placeholder: alpha(colors.gray, opacities.placeholder),
    editor: '#EBECEA'
}

const presenceColors = [colors.orange, colors.mint, colors.pink, colors.hotRed, colors.sky, colors.blue]

export default {
    brandColors,
    colors: {
        ...colors,
        grays
    },
    presenceColors,
    opacities,
    border: {
        soft: grays.softer,
        medium: grays.medium,
        regular: grays.soft,
        dark: grays.hard,
        light: colors.white,
        lightPlum: alpha(colors.plum, opacities.softer)
    },
    background: {
        white: colors.white,
        light: colors.offWhite,
        hover: grays.softer,
        down: grays.softer,
        dark: grays.hard,
        opaque: grays.opaque,
        editor: {
            opaque: grays.editor,
            semi: alpha(grays.editor, 0.96),
            half: alpha(grays.editor, 0.5)
        },
        placeholderOpaque: '#F1F2F0'
    },
    text: {
        regular: grays.hard,
        secondary: grays.medium,
        white: colors.offWhite
    },
    button: {
        regular: {
            default: colors.pink,
            hover: lighten(colors.pink, 0.1),
            down: darken(colors.pink, 0.2)
        },
        gray: {
            default: grays.softer,
            hover: colors.offWhite,
            down: darken(colors.offWhite, 0.1)
        }
    },
    icon: {
        regular: grays.hard,
        selected: grays.hard,
        hover: grays.hard,
        down: grays.hard,
        disabled: grays.soft
    },
    snapline: {
        sceneBoundsSnap: colors.hotRed,
        dynamicBoundsSnap: colors.blue
    },
    shadow: {
        regular: alpha(colors.black, 0.35)
    },
    manipulators: {
        selection: colors.blue,
        handleFill: colors.white
    },
    previewReel: {
        mediumGray: alpha(colors.gray, opacities.previewReelMedium)
    },
    loadingBar: colors.sky,
    alpha
}
